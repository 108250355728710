@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
}

.app-container {
    background: rgba(65, 84, 175, 0.15);
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h2 {
    font-size: 1.05em;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
}

.main-content {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.tree-builder,
.latex-output,
.pdf-preview {
    margin-bottom: 1.5rem;
}

.node {
    background: white;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    padding: 0.8rem;
    padding-bottom: 0rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.node:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.child-node {
    margin-left: 1.5rem;
}

.node .card-content {
    padding-bottom: 0.5rem;
}

.children-container {
    margin-top: 0.5rem;
    padding-bottom: 0rem;
}

.input {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.input:focus {
    border-color: #3498db;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    margin-top: 1rem;
}

@media (max-width: 640px) {
    .button-group .button {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.options {
    /* background-color: #f0f0f0; */
    /* background-color: #e5e4e2; */
    /* border-radius: 8px; */
    margin-bottom: 20px;
    transition: all 0.3s ease;
}

.options.closed {
    max-height: 80px;
    overflow: hidden;
}

.options-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
    cursor: pointer;
}

.options-title {
    margin: 0;
    text-align: center;
}

.options-toggle {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.options-content {
    padding: 0 20px 20px;
}

.option-group {
    margin-bottom: 15px;
}

.error-message {
    color: #e74c3c;
    background-color: #fadbd8;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.input-with-tooltip {
    position: relative;
    display: inline-block;
}

.input-with-tooltip .tooltip {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.input-with-tooltip:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.latex-textarea {
    width: 100%;
    height: 300px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0.5rem;
    font-family: monospace;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.pdf-iframe {
    width: 100%;
    height: 500px;
    border: none;
    border-radius: 4px;
}

.buy-me-coffee-container {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .main-content {
        padding: 1rem;
    }

    .child-node {
        margin-left: 1rem;
    }
}
